import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { removeFilter } from "./actions";

class SelectedFilters extends React.Component {
  render() {
    const {
      projectId,
      selectedFilters,
      removeFilter,
      searchField,
      sortFilter
    } = this.props;

    return (
      <div>
        {selectedFilters.length >= 1 &&
          selectedFilters.map(filter => (
            <div key={filter.id} className="selected-filter">
              <React.Fragment>
                <span className="selected-filter__text">{filter.name}</span>
                <button
                  className="selected-filter__btn icon-default"
                  type="button"
                  onClick={() =>
                    removeFilter(
                      1,
                      projectId,
                      selectedFilters,
                      filter,
                      searchField.searchParam,
                      sortFilter
                    )
                  }
                >
                  <span>&nbsp;</span>
                </button>
              </React.Fragment>
            </div>
          ))}
      </div>
    );
  }
}

SelectedFilters.propTypes = {
  removeFilter: PropTypes.func,
  projectId: PropTypes.number,
  selectedFilters: PropTypes.array,
  searchField: PropTypes.object,
  sortFilter: PropTypes.string
};

SelectedFilters.defaultProps = {
  selectedFilters: [],
  removeFilter: () => {},
  searchField: {},
  projectId: undefined,
  sortFilter: "-last_updated"
};

const mapDispatchToProps = dispatch => ({
  removeFilter: (page, projectId, selectedFilters, filter, search) =>
    dispatch(removeFilter(page, projectId, selectedFilters, filter, search))
});
const mapStateToProps = state => ({
  projectId: state.projectDetail.projectInfo.id,
  selectedFilters: state.projectDetail.selectedFilters,
  searchField: state.projectDetail.searchField,
  page: state.projectDetail.page,
  sortFilter: state.projectDetail.sortFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFilters);
