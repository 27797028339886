import axios from "axios";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

import {
  SET_TOKEN,
  SET_CURRENT_USER,
  UNSET_CURRENT_USER,
  SET_LOGIN_ERROR_MSG,
  CLEAR_LOGIN_ERRORS
} from "./types";
import { setAxiosAuthToken, toastOnError } from "../Utils";

export const setCurrentUser = (user, redirectTo) => dispatch => {
  localStorage.setItem("user", JSON.stringify(user));
  dispatch({
    type: SET_CURRENT_USER,
    payload: user
  });

  if (redirectTo !== "") {
    dispatch(push(redirectTo));
  }
};
export const unsetCurrentUser = () => dispatch => {
  setAxiosAuthToken("");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch({
    type: UNSET_CURRENT_USER
  });
};

export const setToken = token => dispatch => {
  setAxiosAuthToken(token);
  localStorage.setItem("token", token);
  dispatch({
    type: SET_TOKEN,
    payload: token
  });
};

export const getCurrentUser = redirectTo => dispatch => {
  axios
    .get(window.API_URL + "/auth/users/me/")
    .then(response => {
      const user = {
        username: response.data.username,
        email: response.data.email,
        profile: response.data.profile,
        permissions: response.data.permissions
      };
      dispatch(setCurrentUser(user, redirectTo));
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
    });
};

export const login = (userData, redirectTo) => dispatch => {
  axios
    .post(window.API_URL + "/auth/token/login/", userData)
    .then(response => {
      const authToken = response.data.auth_token;
      setAxiosAuthToken(authToken);
      dispatch(setToken(authToken));
      dispatch(getCurrentUser(redirectTo));
    })
    .catch(error => {
      const errorMessage = "Invalid username or password";
      dispatch(unsetCurrentUser());
      toastOnError(error);
      dispatch({
        type: SET_LOGIN_ERROR_MSG,
        payload: errorMessage
      });
    });
};

export const logout = () => dispatch => {
  axios
    .post(window.API_URL + "/auth/token/logout/")
    .then(() => {
      dispatch(unsetCurrentUser());
      dispatch(push("/"));
      toast.success("Logout successful.");
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
    });
};

export const cleanErrorMsg = () => dispatch =>
  dispatch({ type: CLEAR_LOGIN_ERRORS });
