import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import NoMatch404 from "./NoMatch404";

import { history } from "./configStore";
// import BaseTemplate from "./BaseTemplate";
import LoginPage from "./login";
import ProjectDetail from "./ProjectDetail";
import OfferDetail from "./ProjectDetail/OfferDetail";

import requireAuth from "./RequireAuth";

export default class Routes extends React.Component {
  render() {
    return (
      <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route
              exact
              path="/project-detail"
              component={requireAuth(ProjectDetail)}
            />
            <Route
              exact
              path="/project-detail/offers/:id"
              component={requireAuth(OfferDetail)}
            />
            <Route component={NoMatch404} />
          </Switch>
      </ConnectedRouter>
    );
  }
}
