import $ from "jquery";
import CurrencyInput from "react-currency-input-field";
import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import {
  createOffer,
  clearCreateOfferIsSuccessful,
  clearAutofillProduct,
  fetchProductListByName,
  fetchProductListByProductCode
} from "./actions";

function renderSuggestion(suggestion) {
  return <button>{suggestion.name}</button>;
}

class CreateOfferModal extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      url: "",
      marketplace: "",
      productCode: "",
      productName: "",
      seller: "",
      price: "",
      location: "",
      isProductAutofilled: false,
      project: props.projectId, // hardcoded
      isvisible: props.isvisible
    };
    this.state = { ...this.initialState };
    this.debounceFetchProductsByProductCode = debounce(
      this.fetchProductsByProductCode,
      800
    );
  }

  componentDidUpdate(prevProps) {
    const {
      createOfferIsSuccessful,
      handleOfferCreation,
      clearCreateOfferIsSuccessful,
      fetchProductForAutofillIsSuccessful,
      clearAutofillProduct,
      productList
    } = this.props;
    if (createOfferIsSuccessful && !prevProps.createOfferIsSuccessful) {
      this.setState({ ...this.initialState });
      handleOfferCreation();
      clearCreateOfferIsSuccessful();
      // $("#createOffer").modal("hide");
    }

    if (
      fetchProductForAutofillIsSuccessful &&
      !prevProps.fetchProductForAutofillIsSuccessful
    ) {
      this.setState({
        productName: productList.length ? productList[0].name : "",
        isProductAutofilled: productList.length ? true : false
      });
      clearAutofillProduct();
    }
  }

  handleSubmit = () => {
    const { createOffer } = this.props;
    const {
      url,
      marketplace,
      productCode,
      productName,
      seller,
      price,
      location,
      project
    } = this.state;
    createOffer(
      url,
      marketplace,
      productCode,
      productName,
      seller,
      price,
      location,
      project
    );
  };

  handleInputChange = e => {
    const { value, name } = e.target;

    this.setState({
      [name]: value
    });

    if (value && name === "productCode") {
      this.debounceFetchProductsByProductCode();
    }
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => suggestion.name;

  onChange = (event, { newValue }) => {
    this.setState({
      productName: newValue
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  fetchProductsByName = ({ value }) => {
    const { fetchProductListByName } = this.props;
    const { project } = this.state;
    fetchProductListByName(value, project);
  };

  fetchProductsByProductCode = () => {
    const { productCode, project } = this.state;
    const { fetchProductListByProductCode } = this.props;
    fetchProductListByProductCode(productCode, project);
  };

  enableProductInput = () => {
    this.setState({ isProductAutofilled: false });
  };

  render() {
    const {
      createOfferError,
      productList,
      isFetchingProductForAutofill
    } = this.props;
    const {
      url,
      marketplace,
      productCode,
      productName,
      seller,
      price,
      location,
      isProductAutofilled
    } = this.state;

    const isFormEnabled =
      url.length > 0 &&
      marketplace.length > 0 &&
      productCode.length > 0 &&
      productName.length > 0 &&
      seller.length > 0 &&
      price.length > 0 &&
      location.length > 0;

    const inputProps = {
      placeholder: "Choose a product",
      value: productName,
      onChange: this.onChange,
      disabled: isProductAutofilled
    };

    const theme = {
      container: "autosuggest",
      input: "form-control",
      suggestionsContainer: "dropdown",
      suggestionsList: `dropdown-menu ${productList.length ? "show" : ""}`,
      suggestion: "dropdown-item",
      suggestionHighlighted: "active"
    };

    return (
      <div
        className="modal add-offer-modal"
        id="createOffer"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createOfferLabel"
        aria-hidden="true"
        style={{ 
          display: this.props.isvisible ? "block" : "none"
         }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Offer
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onmodalclose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {createOfferError &&
                Object.keys(createOfferError).map(elem => (
                  <div
                    className="alert alert-dismissible fade show modal-error"
                    role="alert"
                    key={elem}
                  >
                    <span className="error-title">Error</span>
                    <span className="error-text">{createOfferError[elem]}</span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    />
                  </div>
                ))}

              <form>
                <div className="form-group">
                  <label
                    htmlFor="newOfferUrl"
                    className={`add__offer-label ${
                      createOfferError && createOfferError.url
                        ? "add__offer-label--has-error"
                        : ""
                    }`}
                  >
                    url
                  </label>
                  <input
                    type="text"
                    name="url"
                    value={url}
                    className={`form-control add__offer-input ${
                      createOfferError && createOfferError.url
                        ? "add__offer-input--has-error"
                        : ""
                    }`}
                    id="newOfferUrl"
                    placeholder="Offer's link"
                    onChange={this.handleInputChange}
                  />
                  <small className="form-text text-muted">
                    Urls must start with http or https.
                  </small>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="newOfferMarketplace"
                    className={`add__offer-label ${
                      createOfferError && createOfferError.marketplace
                        ? "add__offer-label--has-error"
                        : ""
                    }`}
                  >
                    marketplace
                  </label>
                  <input
                    type="text"
                    name="marketplace"
                    value={marketplace}
                    className={`form-control add__offer-input ${
                      createOfferError && createOfferError.marketplace
                        ? "add__offer-input--has-error"
                        : ""
                    }`}
                    id="newOfferMarketplace"
                    placeholder="Marketplace name"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="newOfferProductCode"
                    className={`add__offer-label ${
                      createOfferError && createOfferError.product_code
                        ? "add__offer-label--has-error"
                        : ""
                    }`}
                  >
                    product id
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="productCode"
                      value={productCode}
                      className={`form-control add__offer-input ${
                        createOfferError && createOfferError.product_code
                          ? "add__offer-input--has-error"
                          : ""
                      }`}
                      id="newOfferProductCode"
                      placeholder="ASIN, SKU or other reference"
                      onChange={this.handleInputChange}
                      onFocus={this.enableProductInput}
                    />
                    <span
                      className={`spinner-border spinner-border-sm ${
                        !isFetchingProductForAutofill ? "invisible" : ""
                      }`}
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="newOfferProduct"
                    className={`add__offer-label ${
                      createOfferError && createOfferError.product
                        ? "add__offer-label--has-error"
                        : ""
                    }`}
                  >
                    product
                  </label>
                  <Autosuggest
                    suggestions={productList}
                    onSuggestionsFetchRequested={this.fetchProductsByName}
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    theme={theme}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="newOfferSeller"
                    className={`add__offer-label ${
                      createOfferError && createOfferError.seller
                        ? "add__offer-label--has-error"
                        : ""
                    }`}
                  >
                    seller
                  </label>
                  <input
                    type="text"
                    name="seller"
                    value={seller}
                    className={`form-control add__offer-input ${
                      createOfferError && createOfferError.seller
                        ? "add__offer-input--has-error"
                        : ""
                    }`}
                    id="newOfferSeller"
                    placeholder="Seller's username"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="newOfferPrice"
                    className={`add__offer-label ${
                      createOfferError && createOfferError.price
                        ? "add__offer-label--has-error"
                        : ""
                    }`}
                  >
                    price
                  </label>
                  <CurrencyInput
                    name="price"
                    value={price}
                    placeholder="Offer's Price"
                    className={`form-control add__offer-input ${
                      createOfferError && createOfferError.price
                        ? "add__offer-input--has-error"
                        : ""
                    }`}
                    decimalsLimit={2}
                    decimalSeparator=","
                    groupSeparator="."
                    onValueChange={value => this.setState({ price: value })}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="newOfferCountry"
                    className={`add__offer-label ${
                      createOfferError && createOfferError.location
                        ? "add__offer-label--has-error"
                        : ""
                    }`}
                  >
                    location
                  </label>
                  <input
                    type="text"
                    name="location"
                    value={location}
                    className={`form-control add__offer-input ${
                      createOfferError && createOfferError.location
                        ? "add__offer-input--has-error"
                        : ""
                    }`}
                    id="newOfferCountry"
                    placeholder="Offer's Country"
                    onChange={this.handleInputChange}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                disabled={!isFormEnabled}
                className="btn btn-primary"
                onClick={this.handleSubmit}
              >
                Add Offer
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateOfferModal.defaultProps = {
  createOffer: undefined,
  createOfferIsSuccessful: false,
  handleOfferCreation: undefined,
  createOfferError: undefined,
  productList: [],
  fetchProductListByName: undefined,
  fetchProductListByProductCode: undefined,
  clearCreateOfferIsSuccessful: undefined,
  clearAutofillProduct: undefined,
  isFetchingProductForAutofill: false,
  fetchProductForAutofillIsSuccessful: false
};

CreateOfferModal.propTypes = {
  createOffer: PropTypes.func,
  createOfferIsSuccessful: PropTypes.bool,
  handleOfferCreation: PropTypes.func,
  createOfferError: PropTypes.object,
  productList: PropTypes.array,
  fetchProductListByName: PropTypes.func,
  fetchProductListByProductCode: PropTypes.func,
  clearCreateOfferIsSuccessful: PropTypes.func,
  clearAutofillProduct: PropTypes.func,
  isFetchingProductForAutofill: PropTypes.bool,
  fetchProductForAutofillIsSuccessful: PropTypes.bool
};

const mapStateToProps = state => ({
  createOfferError: state.projectDetail.createOfferError,
  createOfferIsSuccessful: state.projectDetail.createOfferIsSuccessful,
  productList: state.projectDetail.productList,
  isFetchingProductForAutofill:
    state.projectDetail.isFetchingProductForAutofill,
  fetchProductForAutofillIsSuccessful:
    state.projectDetail.fetchProductForAutofillIsSuccessful
});

const mapDispatchToProps = dispatch => ({
  createOffer: (
    url,
    marketplace,
    productCode,
    productName,
    seller,
    price,
    location,
    project
  ) =>
    dispatch(
      createOffer(
        url,
        marketplace,
        productCode,
        productName,
        seller,
        price,
        location,
        project
      )
    ),
  clearCreateOfferIsSuccessful: () => dispatch(clearCreateOfferIsSuccessful()),
  clearAutofillProduct: () => dispatch(clearAutofillProduct()),
  fetchProductListByName: (name, projectId) =>
    dispatch(fetchProductListByName(name, projectId)),
  fetchProductListByProductCode: (productCode, projectId) =>
    dispatch(fetchProductListByProductCode(productCode, projectId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateOfferModal));
