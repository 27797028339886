import $ from "jquery";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateOffer } from "./actions";

class OfferModal extends React.Component {
  handleSubmit = () => {
    const { eventName, offerId, offerStatus, updateOffer } = this.props;
    updateOffer(offerId, {
      status: offerStatus
    });
    $(`#${eventName}OfferModal`).modal("hide");
  };

  render() {
    const { eventName, offerId, productName } = this.props;

    let eventAction = "";
    if (eventName === "approve") {
      eventAction = "approval";
    } else if (eventName === "reject") {
      eventAction = "rejection";
    }

    return (
      <div
        className="modal fade offer-modal"
        id={`${eventName}OfferModal`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`${eventName}OfferModalLabel`}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3
                className={`modal-title ${eventName}`}
                id="exampleModalLabel"
                style={{ textTransform: "capitalize" }}
              >
                {eventName} Purchase
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="confirmation-message">
                <span className="confirmation-message__title">
                  Are you sure you want to {eventName} purchase of offer{" "}
                  <b>
                    {offerId} - {productName}?
                  </b>
                </span>
                <span className="confirmation-message__subtitle">
                  Purchase {eventAction} is an action that{" "}
                  <b>can not be undone</b> after your confirmation.
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-${eventName}`}
                onClick={this.handleSubmit}
              >
                <span style={{ textTransform: "capitalize" }}>{eventName}</span>
              </button>
              <button
                type="button"
                className="btn btn-modal-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OfferModal.defaultProps = {
  updateOffer: undefined,
  eventName: "approval",
  productName: "",
  offerId: null,
  offerStatus: ""
};

OfferModal.propTypes = {
  updateOffer: PropTypes.func,
  eventName: PropTypes.string,
  productName: PropTypes.string,
  offerId: PropTypes.number,
  offerStatus: PropTypes.string
};

const mapStateToProps = state => ({
  updateOfferErrorMessage: state.projectDetail.updateOfferErrorMessage,
  updateOfferIsSuccessful: state.projectDetail.updateOfferIsSuccessful
});

const mapDispatchToProps = dispatch => ({
  updateOffer: (offerId, offerStatus) =>
    dispatch(updateOffer(offerId, offerStatus))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferModal));
