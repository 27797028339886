import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import logo from '../static/images/logo.svg';
import warn from '../static/images/icon-warn.svg';


import { login, cleanErrorMsg } from "./actions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {}
    };
  }

  validateFields = () => {
    const { username, password } = this.state;
    const errors = {};

    if (username.length <= 0) {
      errors.username = true;
    }

    if (password.length <= 0) {
      errors.password = true;
    }

    this.setState({ errors });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { username, password } = this.state;

    this.validateFields();

    if (username && password) {
      const { login } = this.props;
      // console.log(username, password);
      login({ username, password }, "/project-detail");
    }
  };

  onChange = e => {
    const { cleanErrorMsg } = this.props;

    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateFields();
    });
    cleanErrorMsg();
  };

  render() {
    const { errorMessage } = this.props;
    const { username, password, errors } = this.state;

    return (
      <div className="login-content">
        <Helmet>
          <title>Æther TestPurchase</title>
          <meta name="description" content="Test Purchase - Login Page" />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col">
              <div>
                <h1 className="login-content__title">Welcome</h1>
                <img src={logo} alt="Logo" />
              </div>
            </div>
            <div className="col">
              <span className="login-content__subtitle">
                Login to your account
              </span>
              <form className="login__form" onSubmit={this.handleSubmit}>
                <div
                  id="form-group-username"
                  className={`form-group login__form-group ${
                    errors.username ? "login__form-group--has-error" : ""
                  }`}
                >
                  <label
                    htmlFor="username"
                    className={`login__form-label ${
                      errors.username ? "login__form-label--has-error" : ""
                    }`}
                  >
                    Login
                  </label>
                  <input
                    type="text"
                    className={`form-control login__form-input ${
                      errors.username ? "login__form-input--has-error" : ""
                    }`}
                    id="username"
                    placeholder="Enter your username"
                    name="username"
                    value={username}
                    autoComplete="off"
                    onChange={this.onChange}
                  />
                </div>
                <div
                  id="form-group-password"
                  className={`form-group login__form-group ${
                    errors.password ? "login__form-group--has-error" : ""
                  }`}
                >
                  <label
                    htmlFor="password"
                    className={`login__form-label ${
                      errors.password ? "login__form-label--has-error" : ""
                    }`}
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className={`form-control login__form-input ${
                      errors.password ? "login__form-input--has-error" : ""
                    }`}
                    id="password"
                    placeholder="Enter your password"
                    name="password"
                    value={password}
                    onChange={this.onChange}
                  />
                </div>
                <div className="login__form-submit-row">
                  <button
                    type="submit"
                    className="btn btn-primary login__form-button"
                    onClick={this.cleanError}
                  >
                    Login
                  </button>
                 {errorMessage ? (
                    <span className="login__form-error-msg">
                      <img src={warn} alt="warn" />
                      {errorMessage}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  cleanErrorMsg: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

Login.defaultProps = {
  errorMessage: undefined
};

const mapStateToProps = state => ({
  errorMessage: state.auth.errorMessage
});

const mapDispatchToProps = {
  login,
  cleanErrorMsg
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
