import {
  SET_TOKEN,
  SET_CURRENT_USER,
  UNSET_CURRENT_USER,
  SET_LOGIN_ERROR_MSG,
  CLEAR_LOGIN_ERRORS
} from "./types";

const initialState = {
  isAuthenticated: false,
  user: {},
  token: "",
  errorMessage: ""
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload
      };
    case SET_LOGIN_ERROR_MSG:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CLEAR_LOGIN_ERRORS:
      return {
        ...state,
        errorMessage: initialState.errorMessage
      };
    case UNSET_CURRENT_USER:
      return initialState;
    default:
      return state;
  }
};
