import React from "react";
import PropTypes from "prop-types";

class OfferFilterInputListItem extends React.Component {
  render() {
    const { checkedItems, onChange, item } = this.props;
    const isChecked = checkedItems.filter(
      checkedItem => checkedItem.id === item.id
    ).length;

    return (
      <li className="offer-filter-input__list-item">
        <div className={`form-check ${isChecked ? "form-check--checked" : ""}`}>
          <input
            className="form-check-input"
            type="checkbox"
            name="filterCheckbox"
            id={item.name}
            onChange={() => onChange(item)}
            checked={isChecked}
          />
          <label className="form-check-label  check-box" htmlFor={item.name}>
            <span title={item.name}>{item.name}</span>
          </label>
        </div>
      </li>
    );
  }
}

OfferFilterInputListItem.propTypes = {
  item: PropTypes.object.isRequired,
  checkedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default OfferFilterInputListItem;
