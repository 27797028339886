import React from "react";
import ReactDOM from "react-dom";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";

import configureStore from "./configStore";
import Routes from "./Routes";
import "./scss/main.scss";
import "bootstrap";

const store = configureStore();

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}

export default hot(App);


ReactDOM.render(<App />, document.getElementById("root"));
