import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import xorBy from "lodash/xorBy";
import { fetchProductListByName, fetchOfferList } from "./actions";
import OfferFilterInput from "./OfferFilterInput";
import { OFFER_STATUS } from "../constants";

class OfferFilters extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = {
      checkedProducts: [],
      checkedStatus: [],
      showFilters: false,
      openedFilter: undefined
    };
    this.filterBoxElement = React.createRef();
    this.filterBoxElement2 = React.createRef();
  }

  componentDidMount() {
    this.fetchProductsByName();

    // div of the filters will be closed when there is a click outside it
    document.documentElement.addEventListener(
      "click",
      this.closeFilterBoxOnOutsideClick
    );
  }

  componentDidUpdate(prevProps) {
    const { selectedFilters } = this.props;

    if (prevProps.selectedFilters.length !== selectedFilters.length) {
      this.syncSelectedFilters();
    }
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener(
      "click",
      this.closeFilterBoxOnOutsideClick
    );
  }

  syncSelectedFilters = () => {
    const { selectedFilters } = this.props;
    const productFilters = selectedFilters.filter(
      filter => filter.filterType === "product"
    );
    const statusFilters = selectedFilters.filter(
      filter => filter.filterType === "status"
    );
    this.setState({
      checkedProducts: productFilters,
      checkedStatus: statusFilters
    });
  };

  closeFilterBoxOnOutsideClick = e => {
    const { showFilters } = this.state;
    // console.log(this.filterBoxElement2.current.children[0] == e.target);
    // console.log(e.target == this.filterBoxElement2.current);
    if (showFilters && !this.filterBoxElement.current.contains(e.target) && this.filterBoxElement2.current.children[0] != e.target && e.target != this.filterBoxElement2.current) {
      this.toggleStatus();
    }
  };

  fetchProductsByName = () => {
    const { fetchProductListByName, projectId } = this.props;
    fetchProductListByName("", projectId);
  };

  handleSubmit = () => {
    const { fetchOfferList, searchField, projectId, sortFilter } = this.props;
    const { checkedStatus, checkedProducts } = this.state;
    fetchOfferList(
      1,
      projectId,
      checkedProducts,
      checkedStatus,
      searchField.searchParam,
      sortFilter
    );
    this.toggleStatus();
  };

  cleanFilter = () => {
    const { fetchOfferList, projectId, searchField, sortFilter } = this.props;

    fetchOfferList(1, projectId, [], [], searchField.searchParam, sortFilter);
    this.toggleStatus();
  };

  onChangeProducts = item => {
    const { checkedProducts } = this.state;
    this.setState({ checkedProducts: xorBy(checkedProducts, [item], "id") });
  };

  onChangeStatus = item => {
    const { checkedStatus } = this.state;
    this.setState({ checkedStatus: xorBy(checkedStatus, [item], "id") });
  };

  toggleStatus = () => {
    const { showFilters } = this.state;
    this.setState({ showFilters: !showFilters, openedFilter: undefined });
  };

  handleFilterClick = filter => {
    const { openedFilter } = this.state;
    this.setState({
      openedFilter: openedFilter !== filter ? filter : undefined
    });
  };

  render() {
    const {
      checkedProducts,
      showFilters,
      checkedStatus,
      openedFilter
    } = this.state;
    const { productList, offerList } = this.props;
    const needToRenderFiltersBoxOnTop = offerList.length <= 3;
    return (
      <div className="filter-offer">
        <button  ref={this.filterBoxElement2} 
          className={`btn icon-filter ${
            checkedProducts.length >= 1 || checkedStatus.length >= 1
              ? "has-filter-icon"
              : ""
          }`}
          type="button"
          onClick={this.toggleStatus}
        >
          <span>&nbsp;</span>
        </button>
        <div ref={this.filterBoxElement}>
          {showFilters ? (
            <div
              className={`filter-offer__content close-click-outside-js ${
                needToRenderFiltersBoxOnTop
                  ? "filter-offer__content--position-top"
                  : ""
              }`}
            >
              <span className="filter-offer__title">Filter status</span>
              <OfferFilterInput
                label="Product"
                items={productList}
                checkedItems={checkedProducts}
                onChange={this.onChangeProducts}
                isOpen={openedFilter === "Product"}
                onFilterClick={this.handleFilterClick}
              />
              <OfferFilterInput
                label="Status"
                items={OFFER_STATUS}
                checkedItems={checkedStatus}
                onChange={this.onChangeStatus}
                isOpen={openedFilter === "Status"}
                onFilterClick={this.handleFilterClick}
              />
              <div className="group-action-btn">
                <button
                  type="button"
                  className="btn btn-apply"
                  onClick={this.handleSubmit}
                >
                  Apply
                </button>
                <button
                  type="button"
                  className="btn btn-clear-all"
                  onClick={this.cleanFilter}
                >
                  Clear all
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

OfferFilters.propTypes = {
  fetchProductListByName: PropTypes.func.isRequired,
  fetchOfferList: PropTypes.func.isRequired,
  offerList: PropTypes.array,
  productList: PropTypes.array,
  projectId: PropTypes.number.isRequired,
  selectedFilters: PropTypes.array,
  searchField: PropTypes.object,
  sortFilter: PropTypes.string
};

OfferFilters.defaultProps = {
  productList: [],
  searchField: {},
  selectedFilters: [],
  offerList: [],
  sortFilter: "-last_updated"
};

const mapDispatchToProps = dispatch => ({
  fetchProductListByName: (name, projectId) =>
    dispatch(fetchProductListByName(name, projectId)),
  fetchOfferList: (page, projectId, productName, status, search, sortFilter) =>
    dispatch(
      fetchOfferList(page, projectId, productName, status, search, sortFilter)
    )
});

const mapStateToProps = state => ({
  productList: state.projectDetail.productList,
  projectId: state.projectDetail.projectInfo.id,
  selectedFilters: state.projectDetail.selectedFilters,
  searchField: state.projectDetail.searchField,
  page: state.projectDetail.page,
  sortFilter: state.projectDetail.sortFilter,
  offerList: state.projectDetail.offerList
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferFilters));
