import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { fetchOfferList } from "./actions";

class OfferSearch extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = {
      query: "",
      checkedProducts: [],
      checkedStatus: [],
      showInputSearch: false
    };
    this.debounceFetchSearch = debounce(this.fetchSearch, 800);
  }

  componentDidUpdate(prevProps) {
    const { selectedFilters } = this.props;

    if (prevProps.selectedFilters.length !== selectedFilters.length) {
      this.syncSelectedFilters();
    }
  }

  syncSelectedFilters = () => {
    const { selectedFilters } = this.props;
    const productFilters = selectedFilters.filter(
      filter => filter.filterType === "product"
    );
    const statusFilters = selectedFilters.filter(
      filter => filter.filterType === "status"
    );
    this.setState({
      checkedProducts: productFilters,
      checkedStatus: statusFilters
    });
  };

  fetchSearch = () => {
    const { fetchOfferList, projectId, sortFilter } = this.props;
    const { query, checkedProducts, checkedStatus } = this.state;
    fetchOfferList(
      1,
      projectId,
      checkedProducts,
      checkedStatus,
      query,
      sortFilter
    );
  };

  toggleStatus = () => {
    const { showInputSearch } = this.state;
    this.setState({ showInputSearch: !showInputSearch, query: "" }, () => {
      if (showInputSearch) {
        this.fetchSearch();
      }
    });
  };

  onChange = event => {
    this.setState({ query: event.target.value });
    this.debounceFetchSearch();
  };

  render() {
    const { showInputSearch, query } = this.state;

    return (
      <div
        className={`search-offer input-group ${
          showInputSearch ? "search-offer--border" : ""
        }`}
      >
        {showInputSearch ? (
          <input
            type="text"
            className="search-offer__input form-control"
            placeholder="Search for offer"
            value={query}
            onChange={this.onChange}
          />
        ) : null}
        <button
          type="button"
          className={`btn search-offer__icon ${
            showInputSearch ? "search-offer__icon--close" : ""
          }`}
          onClick={this.toggleStatus}
        >
          <span>&nbsp;</span>
        </button>
      </div>
    );
  }
}

OfferSearch.propTypes = {
  fetchOfferList: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
  projectId: PropTypes.number.isRequired,
  sortFilter: PropTypes.string
};

OfferSearch.defaultProps = {
  selectedFilters: [],
  sortFilter: "-last_updated"
};

const mapDispatchToProps = dispatch => ({
  fetchOfferList: (page, projectId, productName, status, search, sortFilter) =>
    dispatch(
      fetchOfferList(page, projectId, productName, status, search, sortFilter)
    )
});

const mapStateToProps = state => ({
  projectId: state.projectDetail.projectInfo.id,
  selectedFilters: state.projectDetail.selectedFilters,
  page: state.projectDetail.page,
  sortFilter: state.projectDetail.sortFilter
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferSearch));
