import _ from "lodash";
import { UNSET_CURRENT_USER } from "../login/types";

import {
  FETCH_OFFER_LIST_REQUEST,
  FETCH_OFFER_LIST_SUCCESS,
  FETCH_OFFER_LIST_FAILURE,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  UPDATE_OFFER_REQUEST,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILURE,
  CLEAR_CREATE_OFFER_IS_SUCCESSFUL,
  FETCH_OFFER_DETAIL_REQUEST,
  FETCH_OFFER_DETAIL_SUCCESS,
  FETCH_OFFER_DETAIL_FAILURE,
  FETCH_NOTES_LIST_REQUEST,
  FETCH_NOTES_LIST_SUCCESS,
  FETCH_NOTES_LIST_FAILURE,
  FETCH_PRODUCT_LIST_REQUEST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_PRODUCT_LIST_FOR_AUTOFILL_REQUEST,
  FETCH_PRODUCT_LIST_FOR_AUTOFILL_SUCCESS,
  FETCH_PRODUCT_LIST_FOR_AUTOFILL_FAILURE,
  FETCH_PROJECT_DETAIL_REQUEST,
  FETCH_PROJECT_DETAIL_SUCCESS,
  FETCH_PROJECT_DETAIL_FAILURE,
  CHANGE_LEGALITY_ANALYSIS_REQUEST,
  CHANGE_LEGALITY_ANALYSIS_SUCCESS,
  CHANGE_LEGALITY_ANALYSIS_FAILURE,
  CLEAR_CHANGE_LEGALITY_ANALYSIS_IS_SUCCESSFUL,
  FETCH_ASSETS_LIST_REQUEST,
  FETCH_ASSETS_LIST_SUCCESS,
  FETCH_ASSETS_LIST_FAILURE,
  CLEAR_AUTOFILL_PRODUCT
} from "./types";

const initialState = {
  offerList: undefined,
  offerListIsLoading: false,
  createOfferIsSuccessful: false,
  createOfferError: undefined,
  noteList: undefined,
  productList: undefined,
  offerIsLoading: false,
  totalPages: undefined,
  count: undefined,
  previous: undefined,
  next: undefined,
  offerDetail: undefined,
  hasError: false,
  projectInfo: undefined,
  changeLegalityAnalysisErrorMessage: undefined,
  changeLegalityAnalysisIsSuccessful: false,
  assetList: undefined,
  isFetchingProductForAutofill: false,
  fetchProductForAutofillIsSuccessful: false,
  queryFilter: undefined,
  page: 1
};

export const projectDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OFFER_LIST_REQUEST:
      return {
        ...state,
        sortFilter: action.extraData.sortFilter,
        queryFilter: action.extraData.queryFilter,
        selectedFilters: action.extraData.selectedFilters,
        searchField: action.extraData.searchField,
        offerList: initialState.offerList,
        offerIsLoading: true,
        hasError: false,
        count: initialState.count,
        previous: initialState.previous,
        next: initialState.next,
        totalPages: initialState.totalPages,
        page: action.extraData.page
      };
    case FETCH_OFFER_LIST_SUCCESS:
      return {
        ...state,
        offerIsLoading: initialState.offerIsLoading,
        offerList: action.response.data.results,
        totalPages: action.response.data.total_pages,
        count: action.response.data.count,
        previous: action.response.data.previous,
        next: action.response.data.next
      };
    case FETCH_OFFER_LIST_FAILURE:
      return {
        ...state,
        offerIsLoading: initialState.offerIsLoading,
        hasError: true
      };
    case FETCH_OFFER_DETAIL_REQUEST:
      return {
        ...state,
        offerDetail: initialState.offerDetail,
        offerIsLoading: true,
        hasError: false
      };
    case FETCH_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        offerDetail: action.response.data,
        offerIsLoading: initialState.offerIsLoading
      };
    case FETCH_OFFER_DETAIL_FAILURE:
      return {
        ...state,
        offerIsLoading: initialState.offerIsLoading,
        hasError: true
      };
    case FETCH_NOTES_LIST_REQUEST:
      return {
        ...state,
        noteList: initialState.noteList,
        hasError: false
      };
    case FETCH_NOTES_LIST_SUCCESS:
      return {
        ...state,
        noteList: action.response.data.results,
        count: action.response.data.count
      };
    case FETCH_NOTES_LIST_FAILURE:
      return {
        ...state,
        hasError: true
      };
    case FETCH_ASSETS_LIST_REQUEST:
      return {
        ...state,
        assetList: initialState.assetList,
        hasError: false
      };
    case FETCH_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        assetList: action.response.data.results,
        count: action.response.data.count
      };
    case FETCH_ASSETS_LIST_FAILURE:
      return {
        ...state,
        hasError: true
      };
    case FETCH_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        productList: initialState.productList
      };
    case FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action.response.data.results
      };
    case FETCH_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        hasError: true
      };
    case FETCH_PRODUCT_LIST_FOR_AUTOFILL_REQUEST:
      return {
        ...state,
        productList: initialState.productList,
        isFetchingProductForAutofill: true
      };
    case FETCH_PRODUCT_LIST_FOR_AUTOFILL_SUCCESS:
      return {
        ...state,
        productList: action.response.data.results,
        fetchProductForAutofillIsSuccessful: true,
        isFetchingProductForAutofill: false
      };
    case FETCH_PRODUCT_LIST_FOR_AUTOFILL_FAILURE:
      return {
        ...state,
        isFetchingProductForAutofill: false,
        fetchProductForAutofillIsSuccessful: false
      };
    case UNSET_CURRENT_USER:
      return initialState;
    case CREATE_OFFER_REQUEST:
      return {
        ...state
      };
    case CREATE_OFFER_SUCCESS:
      return {
        ...state,
        createOfferIsSuccessful: true,
        createOfferError: undefined
      };
    case CREATE_OFFER_FAILURE:
      return {
        ...state,
        createOfferError: action.error.response.data,
        createOfferIsSuccessful: false
      };
    case UPDATE_OFFER_REQUEST:
      return {
        ...state
      };
    case UPDATE_OFFER_SUCCESS:
      const offer = action.response.data;
      const newOfferList = [...state.offerList];
      const offerIndex = _.findIndex(newOfferList, { id: offer.id });
      newOfferList[offerIndex] = offer;

      const newProjectInfo = { ...state.projectInfo };
      if (offer.status !== "PE") {
        newProjectInfo.pending_approval_offers_count -= 1;
      }

      if (state.offerDetail && state.offerDetail.id === offer.id) {
        return {
          ...state,
          offerDetail: offer,
          offerList: newOfferList,
          projectInfo: newProjectInfo
        };
      }
      return { ...state, offerList: newOfferList, projectInfo: newProjectInfo };
    case UPDATE_OFFER_FAILURE:
      return {
        ...state,
        updateOfferErrorMessage: action.error.response.data.non_field_errors[0],
        updateOfferIsSuccessful: false
      };
    case CLEAR_CREATE_OFFER_IS_SUCCESSFUL:
      return {
        ...state,
        createOfferIsSuccessful: false
      };
    case FETCH_PROJECT_DETAIL_REQUEST:
      return {
        ...state
      };
    case FETCH_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectInfo: action.response.data
      };
    case FETCH_PROJECT_DETAIL_FAILURE:
      return {
        ...state
      };
    case CHANGE_LEGALITY_ANALYSIS_REQUEST:
      return {
        ...state
      };
    case CHANGE_LEGALITY_ANALYSIS_SUCCESS:
      return {
        ...state,
        changeLegalityAnalysisIsSuccessful: true,
        changeLegalityAnalysisErrorMessage: ""
      };
    case CHANGE_LEGALITY_ANALYSIS_FAILURE:
      return {
        ...state,
        changeLegalityAnalysisErrorMessage:
          action.error.response.data.non_field_errors[0],
        changeLegalityAnalysisIsSuccessful: false
      };
    case CLEAR_CHANGE_LEGALITY_ANALYSIS_IS_SUCCESSFUL:
      return {
        ...state,
        changeLegalityAnalysisIsSuccessful: false
      };
    case CLEAR_AUTOFILL_PRODUCT:
      return {
        ...state,
        fetchProductForAutofillIsSuccessful: false
      };
    default:
      return state;
  }
};
