import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OfferFilterInputListItem from "./OfferFilterInputListItem";

class OfferFilterInput extends React.Component {
  itemsLabel = (currentValue, i, checkedItems) => {
    const { items } = this.props;
    if (i === checkedItems.length - 1) {
      return `
      ${items.filter(item => item.id === currentValue.id)[0].name}`;
    }
    return `
       ${items.filter(item => item.id === currentValue.id)[0].name}, `;
  };

  render() {
    const {
      label,
      items,
      checkedItems,
      onChange,
      isOpen,
      onFilterClick
    } = this.props;
    return (
      <div className="offer-filter-input">
        <p className="offer-filter-input__label">{label}</p>
        <button
          type="button"
          className={`offer-filter-input__button close-click-outside-js ${
            isOpen ? "offer-filter-input__button-open" : ""
          } `}
          onClick={() => onFilterClick(label)}
        >
          {items.length === checkedItems.length ? <i>All</i> : null}
          {checkedItems.length ? null : <i>All</i>}
          {checkedItems.length && items.length !== checkedItems.length ? (
            <span className="offer-filter-input__checked-items">
              {checkedItems.map(this.itemsLabel)}
            </span>
          ) : null}
          <span
            id="arrowIcon"
            className={`icon-default ${
              isOpen ? "arrow-icon-up" : "arrow-icon-down"
            } `}
          >
            &nbsp;
          </span>
        </button>
        {isOpen ? (
          <ul className="offer-filter-input__list close-click-outside-js">
            {items.map(item => (
              <OfferFilterInputListItem
                key={item.id}
                checkedItems={checkedItems}
                onChange={onChange}
                item={item}
              />
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
}

OfferFilterInput.defaultProps = {
  label: undefined,
  items: undefined,
  checkedItems: undefined,
  isOpen: false,
  onFilterClick: () => {}
};

OfferFilterInput.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  checkedItems: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onFilterClick: PropTypes.func
};

export default connect()(OfferFilterInput);
