export const FETCH_OFFER_LIST_REQUEST = "FETCH_OFFER_LIST_REQUEST";
export const FETCH_OFFER_LIST_SUCCESS = "FETCH_OFFER_LIST_SUCCESS";
export const FETCH_OFFER_LIST_FAILURE = "FETCH_OFFER_LIST_FAILURE";
export const CREATE_OFFER_REQUEST = "CREATE_OFFER_REQUEST";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAILURE = "CREATE_OFFER_FAILURE";
export const UPDATE_OFFER_REQUEST = "UPDATE_OFFER_REQUEST";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILURE = "UPDATE_OFFER_FAILURE";

export const CLEAR_CREATE_OFFER_IS_SUCCESSFUL =
  "CLEAR_CREATE_OFFER_IS_SUCCESSFUL";
export const FETCH_OFFER_DETAIL_REQUEST = "FETCH_OFFER_DETAIL_REQUEST";
export const FETCH_OFFER_DETAIL_SUCCESS = "FETCH_OFFER_DETAIL_SUCCESS";
export const FETCH_OFFER_DETAIL_FAILURE = "FETCH_OFFER_DETAIL_FAILURE";
export const FETCH_NOTES_LIST_SUCCESS = "FETCH_NOTES_LIST_SUCCESS";
export const FETCH_NOTES_LIST_REQUEST = "FETCH_NOTES_LIST_REQUEST";
export const FETCH_NOTES_LIST_FAILURE = "FETCH_NOTES_LIST_FAILURE";

export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_REQUEST = "FETCH_PRODUCT_LIST_REQUEST";
export const FETCH_PRODUCT_LIST_FAILURE = "FETCH_PRODUCT_LIST_FAILURE";

export const FETCH_PRODUCT_LIST_FOR_AUTOFILL_SUCCESS = "FETCH_PRODUCT_LIST_FOR_AUTOFILL_SUCCESS";
export const FETCH_PRODUCT_LIST_FOR_AUTOFILL_REQUEST = "FETCH_PRODUCT_LIST_FOR_AUTOFILL_REQUEST";
export const FETCH_PRODUCT_LIST_FOR_AUTOFILL_FAILURE = "FETCH_PRODUCT_LIST_FOR_AUTOFILL_FAILURE";

export const FETCH_PROJECT_DETAIL_REQUEST = "FETCH_PROJECT_DETAIL_REQUEST";
export const FETCH_PROJECT_DETAIL_SUCCESS = "FETCH_PROJECT_DETAIL_SUCCESS";
export const FETCH_PROJECT_DETAIL_FAILURE = "FETCH_PROJECT_DETAIL_FAILURE";

export const CHANGE_LEGALITY_ANALYSIS_REQUEST =
  "CHANGE_LEGALITY_ANALYSIS_REQUEST";
export const CHANGE_LEGALITY_ANALYSIS_SUCCESS =
  "CHANGE_LEGALITY_ANALYSIS_SUCCESS";
export const CHANGE_LEGALITY_ANALYSIS_FAILURE =
  "CHANGE_LEGALITY_ANALYSIS_FAILURE";
export const CLEAR_CHANGE_LEGALITY_ANALYSIS_IS_SUCCESSFUL =
  "CLEAR_CHANGE_LEGALITY_ANALYSIS_IS_SUCCESSFUL";

export const FETCH_ASSETS_LIST_SUCCESS = "FETCH_ASSETS_LIST_SUCCESS";
export const FETCH_ASSETS_LIST_REQUEST = "FETCH_ASSETS_LIST_REQUEST";
export const FETCH_ASSETS_LIST_FAILURE = "FETCH_ASSETS_LIST_FAILURE";

export const CLEAR_AUTOFILL_PRODUCT = "CLEAR_AUTOFILL_PRODUCT";
