import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { logout } from "./login/actions";

class Header extends React.Component {
  render() {
    const { user, logout } = this.props;

    if (!user) {
      return null;
    }

    return (
      <header>
        <div className="header">
          <div className="header__items">
            <span className="header__items__logo">&nbsp;</span>
            <a
              href="/project-detail"
              className="header__items__link header__items__link--active"
            >
              PROJECT
            </a>
            <div className="header__items-right">
              <span className="header__items__username">{user.username}</span>
              <button type="button" className="btn btn-logout" onClick={logout}>
                <span className="icon-default icon-logout">&nbsp;</span>
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
Header.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired
};

Header.defaultProps = {
  user: undefined
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {
  logout
})(withRouter(Header));
