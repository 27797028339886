/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import PreviewAssetModal from "./PreviewAssetModal";
import OfferModal from "./OfferModal";
import BaseTemplate from "../BaseTemplate";


import {
  fetchOfferDetail,
  fetchNotesList,
  changeLegalityAnalysis,
  clearChangeLegalityAnalysisIsSuccessful,
  fetchAssetsList
} from "./actions";
import {
  renameStatusOffer,
  renameTagNote,
  formatDate,
  showModalLegalityAnalysis
} from "../Utils";

class OfferDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalityAnalysisStatus: "",
      textNote: "",
      selectedAsset: {},
      isPreviewModalOpen: false
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    const { fetchOfferDetail, fetchNotesList, fetchAssetsList } = this.props;

    fetchOfferDetail(id);
    fetchNotesList(id);
    fetchAssetsList(id);
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    const {
      changeLegalityAnalysisIsSuccessful,
      clearChangeLegalityAnalysisIsSuccessful
    } = this.props;

    if (changeLegalityAnalysisIsSuccessful) {
      this.refetchInitialOfferList(id);
      clearChangeLegalityAnalysisIsSuccessful();
    }
  }

  renameLegalityAnalysis = status => {
    if (status === "P") {
      status = "pending";
    } else if (status === "IL") {
      status = "ilegal";
    } else if (status === "LE") {
      status = "legal";
    }
    return status;
  };

  onChangeLegalityAnalysis = e => {
    this.setState({ legalityAnalysisStatus: e.target.value }, () =>
      showModalLegalityAnalysis()
    );
  };

  onChangeTextNote = e => {
    this.setState({ textNote: e.target.value });
  };

  refetchInitialOfferList = id => {
    const { fetchOfferDetail, fetchNotesList } = this.props;

    setTimeout(() => {
      fetchOfferDetail(id);
      fetchNotesList(id);
    }, 1000);
  };

  hidePreviewModal = () => {
    this.setState({ isPreviewModalOpen: false });
  };

  showAssetPreviewModal = (event, asset) => {
    this.setState({ selectedAsset: asset, isPreviewModalOpen: true });
  };

  submitLegalityAnalysis() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    const { changeLegalityAnalysis } = this.props;
    const { legalityAnalysisStatus, textNote } = this.state;

    changeLegalityAnalysis(id, legalityAnalysisStatus, textNote);
  }

  render() {
    const { user, offerDetail, noteList, assetList } = this.props;
    const {
      legalityAnalysisStatus,
      textNote,
      selectedAsset,
      isPreviewModalOpen
    } = this.state;

    return (
     <BaseTemplate>
      <div>
        <Helmet>
          <title>Offer Detail</title>
          <meta
            name="description"
            content="Test Purchase - Offer Detail Page"
          />
        </Helmet>
        {offerDetail ? (
          <div>
            <div className="row no-gutters">
              <div className="col col-sm-7">
                <div className="offer-detail">
                  <div className="row offer-detail__info">
                    <span className="col-8 offer-detail__info__code">
                      <span className="limit-characters-256">
                        {offerDetail.product_code}
                      </span>
                      <span className="offer-detail__info__button">
                        {offerDetail.status === "AP" ||
                        offerDetail.status === "RE" ? (
                          <button
                            type="button"
                            className="btn btn-icon"
                            tabIndex="0"
                            disabled
                          >
                            <span className="icon-default icon-delete icon-delete--disabled">
                              &nbsp;
                            </span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-icon"
                            tabIndex="0"
                          >
                            <span className="icon-default icon-delete">
                              &nbsp;
                            </span>
                          </button>
                        )}
                      </span>
                    </span>
                    <span className="col-3 product__status">
                      {renameStatusOffer(offerDetail.status)}
                    </span>
                  </div>
                  <div className="row offer-detail__info">
                    <div className="col offer-detail__info__item">
                      <div className="col-4 col-sm-5">
                        <p>company</p>
                        <span>-</span>
                      </div>
                      <div className="col-4 col-sm-4">
                        <p>project</p>
                        <span>{offerDetail.project}</span>
                      </div>
                      <div className="col-3 offer-detail__info__item--approval">
                        <p>purchase approval</p>
                        {offerDetail.status === "PE" ? (
                          (user.permissions.includes('core.allow_offer_approval') ? (
                          <div className="align-actions">
                            <div className="offer-detail__group-actions">
                              <button
                                type="button"
                                className="btn product__btn-icon"
                                tabIndex="0"
                                data-toggle="modal"
                                data-target="#approveOfferModal"
                              >
                                <span className="icon-default icon-approve">
                                  &nbsp;
                                </span>
                              </button>
                              <OfferModal
                                eventName="approve"
                                offerId={offerDetail.id}
                                productName={offerDetail.product_name}
                                offerStatus="AP"
                              />
                              <button
                                type="button"
                                className="btn product__btn-icon"
                                tabIndex="0"
                                data-toggle="modal"
                                data-target="#rejectOfferModal"
                              >
                                <span className="icon-default icon-recuse">
                                  &nbsp;
                                </span>
                              </button>
                              <OfferModal
                                eventName="reject"
                                offerId={offerDetail.id}
                                productName={offerDetail.product_name}
                                offerStatus="RE"
                              />
                            </div>
                          </div>) : (
                            <div className="status-badge">
                              <span className={`badge`}>
                                Pending
                              </span>
                              </div>
                          )
                        )) : (
                          <div className="status-badge">
                            {offerDetail.status === "AP" ||
                            offerDetail.status === "FA" ||
                            offerDetail.status === "PU" ||
                            offerDetail.status === "BO" ||
                            offerDetail.status === "PC" ||
                            offerDetail.status === "NO" ||
                            offerDetail.status === "LA" ? (
                             <span className={`badge tp-badge-approved ${
                              offerDetail.onoff_is_active
                                  ? "no-approval-collumn"
                                  : "approval-collumn"
                              }`}>
                                Approved
                              </span>
                            ) : (
                              ""
                            )}
                            {offerDetail.status === "RE" ? (
                              <span className="badge tp-badge-rejected">
                                Rejected
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="divider" />
                  {offerDetail.status === "PC" ||
                  offerDetail.status === "RE" ? (
                    <div>
                      <div className="label-progress-bar">
                        <span className="no-progress-label">no progress</span>
                      </div>

                      <div className="no-progress">
                        <span>&nbsp;</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="label-progress-bar">
                        <span>progress</span>
                        <span>{offerDetail.progress}%</span>
                      </div>

                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${offerDetail.progress}%` }}
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span>&nbsp;</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col col-sm-5 offer-detail__assets">
                {assetList && assetList.length >= 1 ? (
                  <div className="offer-detail__list-assets">
                    {assetList.map(asset => (
                      <div key={asset.id} className="offer-detail__asset">
                        <div className="offer-detail__asset__group-info">
                          <span className="offer-detail__asset-filename">
                            {asset.filename}
                          </span>
                          <span className="offer-detail__asset-subject">
                            {asset.subject}
                          </span>
                          {asset.text && (
                            <span className="offer-detail__asset-text">
                              {asset.text}
                            </span>
                          )}
                        </div>
                        <span
                          className={`offer-detail__asset-tag tag-${asset.tag}`}
                        >
                          {renameTagNote(asset.tag)}
                        </span>

                        <button
                          className="btn offer-detail__asset-menu"
                          data-toggle="dropdown"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          &nbsp;
                        </button>
                        <div
                          className="dropdown-menu offer-detail__asset__dropdown"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={event =>
                              this.showAssetPreviewModal(event, asset)
                            }
                            data-toggle="modal"
                            data-target={`previewModal-${asset.id}`}
                            data-asset-link={asset.file}
                            data-asset-text={asset.text}
                            data-asset-filename={asset.filename}
                          >
                            Preview
                          </button>
                          {asset.file && (
                            <a
                              className="dropdown-item"
                              href={asset.file}
                              download
                            >
                              Download
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                    <PreviewAssetModal
                      asset={selectedAsset}
                      handleClose={this.hidePreviewModal}
                      show={isPreviewModalOpen}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col col-sm-6">
                <div className="offer-detail__list">
                  {offerDetail.legality_analysis ? (
                    <div className="offer-detail__list__item">
                      <p className="detail-label">Legality Analysis </p>
                      {offerDetail.legality_analysis === "P" ? (
                        <div className="left-spacing-32 form-group">
                          <select
                            id="change"
                            name="legality_analysis"
                            className="form-control tp-select"
                            value={offerDetail.legality_analysis}
                            onChange={this.onChangeLegalityAnalysis}
                          >
                            <option value="P">Pending</option>
                            <option value="IL">Ilegal</option>
                            <option value="LE">Legal</option>
                          </select>
                        </div>
                      ) : (
                        <span className="detail-info">
                          {this.renameLegalityAnalysis(
                            offerDetail.legality_analysis
                          )}
                        </span>
                      )}

                      <div
                        id="textNoteModal"
                        className="modal fade legality-analysis-modal"
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content modal-offer-detail">
                            <div className="modal-header">
                              <h4 className="modal-title">Legality Analysis</h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  onChange={this.onChangeTextNote}
                                  rows="8"
                                />
                              </div>
                            </div>
                            <div className="modal-footer">
                              {textNote ? (
                                <button
                                  type="button"
                                  className="btn btn-modal-confirm"
                                  data-toggle="modal"
                                  data-target="#modalConfirmation"
                                  data-dismiss="modal"
                                >
                                  Confirm
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-modal-confirm"
                                  data-dismiss="modal"
                                  disabled
                                >
                                  Confirm
                                </button>
                              )}
                              <button
                                type="button"
                                className="btn btn-modal-default"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    " "
                  )}

                  {/* <!--Modal change legality analysis status confirmation--> */}
                  <div
                    id="modalConfirmation"
                    className="modal fade legality-analysis-modal"
                    role="dialog"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content modal-offer-detail">
                        <div className="modal-header">
                          <h4 className="modal-title">Legality Analysis</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="confirmation-message">
                            <span className="confirmation-message__title">
                              Are you sure you want to change Legality
                              Analysis's status to{" "}
                              <b>
                                {this.renameLegalityAnalysis(
                                  legalityAnalysisStatus
                                )}
                              </b>
                              ?
                            </span>
                            <span className="confirmation-message__subtitle">
                              Remember: you can’t change the status once
                              confirmed.
                            </span>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-modal-confirm"
                            data-dismiss="modal"
                            onClick={() => this.submitLegalityAnalysis()}
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="btn btn-modal-default"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">URL</span>
                    <span className="detail-info">
                      <a
                        className="detail-url"
                        title={offerDetail.url}
                        href={offerDetail.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {offerDetail.url}
                      </a>
                    </span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">Marketplace</span>
                    <span className="detail-info">
                      {offerDetail.marketplace}
                    </span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">Last Update</span>
                    <span className="detail-info">
                      {formatDate(offerDetail.last_updated)}
                    </span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">Seller</span>
                    <span className="detail-info">{offerDetail.seller}</span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">Active</span>
                    <span
                      className={`left-spacing-32 badge tp-badge-onoff-active ${
                        offerDetail.onoff_is_active
                          ? "tp-badge-onoff-active--yes"
                          : "tp-badge-onoff-active--no"
                      }`}
                    >
                      {offerDetail.onoff_is_active ? "yes" : "no"}
                    </span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">Price</span>
                    <span className="detail-info">R$ {offerDetail.price}</span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">Order ID</span>
                    <span className="detail-info">{offerDetail.order_id}</span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">User Buying</span>
                    <span className="detail-info">{offerDetail.buyer}</span>
                  </div>
                  <div className="offer-detail__list__item">
                    <span className="detail-label">Location</span>
                    <span className="detail-info">{offerDetail.location}</span>
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-sm-6">
                <div className="offer-detail__notes-list">
                  {noteList &&
                    noteList.map(note => (
                      <div key={note.id} className="offer-detail__note">
                        <div>
                          <span className="offer-detail__note-user">
                            {note.user_name}
                          </span>
                          <span className="offer-detail__note-created">
                            {formatDate(note.created_at)}
                          </span>

                          <span
                            className={`offer-detail__note-tag tag-${note.tag}`}
                          >
                            {renameTagNote(note.tag)}
                          </span>
                        </div>

                        <span className="offer-detail__note-text">
                          {note.text}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </BaseTemplate>
    );
  }
}

OfferDetail.propTypes = {
  fetchOfferDetail: PropTypes.func.isRequired,
  fetchNotesList: PropTypes.func.isRequired,
  fetchAssetsList: PropTypes.func.isRequired,
  changeLegalityAnalysis: PropTypes.func.isRequired,
  clearChangeLegalityAnalysisIsSuccessful: PropTypes.func.isRequired,
  offerDetail: PropTypes.object,
  noteList: PropTypes.array,
  assetList: PropTypes.array,
  match: PropTypes.object,
  changeLegalityAnalysisIsSuccessful: PropTypes.bool
};

OfferDetail.defaultProps = {
  offerDetail: undefined,
  match: undefined,
  noteList: undefined,
  changeLegalityAnalysisIsSuccessful: false,
  assetList: undefined
};

const mapStateToProps = state => ({
  offerDetail: state.projectDetail.offerDetail,
  noteList: state.projectDetail.noteList,
  changeLegalityAnalysisIsSuccessful:
  state.projectDetail.changeLegalityAnalysisIsSuccessful,
  assetList: state.projectDetail.assetList,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  fetchOfferDetail: id => dispatch(fetchOfferDetail(id)),
  fetchNotesList: id => dispatch(fetchNotesList(id)),
  changeLegalityAnalysis: (id, legalityAnalysis, noteText) =>
    dispatch(changeLegalityAnalysis(id, legalityAnalysis, noteText)),
  clearChangeLegalityAnalysisIsSuccessful: () =>
    dispatch(clearChangeLegalityAnalysisIsSuccessful()),
  fetchAssetsList: id => dispatch(fetchAssetsList(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferDetail));
