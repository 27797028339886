import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import OfferList from "./OfferList";
import { fetchProjectDetail } from "./actions";
import { logout } from "../login/actions";
import  BaseTemplate from "../BaseTemplate";

class ProjectDetail extends Component {
  componentDidMount() {
    const { fetchProjectDetail, auth } = this.props;
    const projectId = auth.user.profile.account.projects[0].id; // hardcoded project index until we have project selection page
    fetchProjectDetail(projectId);
  }

  onLogout = () => {
    const { logout } = this.props;
    logout();
  };

  render() {
    const { projectInfo } = this.props;

    return (
    <BaseTemplate>
      <div className="home">
        <Helmet>
          <title>Æther TestPurchase</title>
          <meta name="description" content="Test Purchase - Home Page" />
        </Helmet>
        {projectInfo && (
          <React.Fragment>
            <div className="home__info-project">
              <div className="row">
                <div className="col">
                  <h1
                    className="home__info-project__title"
                    title={projectInfo.name}
                  >
                    {projectInfo.name}
                  </h1>
                  <h1 className="home__info-project__sub-title">
                    {projectInfo.company.name}
                  </h1>
                </div>
                <div className="col">
                  <div className="home__info-project__card-group">
                    <div className="home__info-project__card">
                      <p className="home__info-project__card-text">OFFERS</p>
                      <p className="home__info-project__card-value">
                        {projectInfo.offers_count}
                      </p>
                    </div>
                    <div className="home__info-project__card">
                      <p className="home__info-project__card-text">OFFERS DOWN</p>
                      <p className="home__info-project__card-value">
                        {projectInfo.offers_down_count}
                      </p>
                    </div>
                    <div className="home__info-project__card">
                      <p className="home__info-project__card-text--larger">
                        PENDING APPROVAL
                      </p>
                      <p className="home__info-project__card-value">
                        {projectInfo.pending_approval_offers_count}
                      </p>
                    </div>
                    <div className="home__info-project__card">
                      <p className="home__info-project__card-text--larger">
                        PENDING LEGAL ANALYSIS
                      </p>
                      <p className="home__info-project__card-value">
                        {projectInfo.pending_legal_analysis_offers_count}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <OfferList projectId={projectInfo.id} />
          </React.Fragment>
        )}
      </div>
     </BaseTemplate>
    );
  }
}

ProjectDetail.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  projectInfo: PropTypes.object,
  fetchProjectDetail: PropTypes.func.isRequired
};

ProjectDetail.defaultProps = {
  projectInfo: undefined
}

const mapStateToProps = state => ({
  auth: state.auth,
  projectInfo: state.projectDetail.projectInfo
});

const mapDispatchToProps = dispatch => ({
  fetchProjectDetail: projectId => dispatch(fetchProjectDetail(projectId)),
  logout
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectDetail));
