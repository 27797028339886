import React, { Component } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import PropTypes, { number } from "prop-types";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { fetchOfferList } from "./actions";
import CreateOfferModal from "./CreateOfferModal";
import OfferFilters from "./OfferFilters";
import OfferModal from "./OfferModal";
import { renameStatusOffer, formatDate } from "../Utils";
import SelectedFilters from "./SelectedFilters";
import OfferSearch from "./OfferSearch";

class OfferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToPage: "",
      checkedProducts: [],
      checkedStatus: [],
      isModalVisible: false
    };
  }

  componentDidMount() {
    const { fetchOfferList, projectId, searchField, sortFilter } = this.props;
    const { checkedStatus, checkedProducts } = this.state;
    fetchOfferList(
      1,
      projectId,
      checkedProducts,
      checkedStatus,
      searchField.searchParam,
      sortFilter
    );
  }

  componentDidUpdate(prevProps) {
    const { selectedFilters } = this.props;

    if (prevProps.selectedFilters.length !== selectedFilters.length) {
      this.syncSelectedFilters();
    }
  }

  refetchInitialOfferList = () => {
    const { fetchOfferList, projectId, searchField, sortFilter } = this.props;
    const { checkedStatus, checkedProducts } = this.state;

    fetchOfferList(
      1,
      projectId,
      checkedProducts,
      checkedStatus,
      searchField.searchParam,
      sortFilter
    );
  };

  handlePageChange = e => {
    const { fetchOfferList, projectId, searchField, sortFilter } = this.props;
    const { checkedStatus, checkedProducts } = this.state;
    const selectedPage = e.selected;
    fetchOfferList(
      selectedPage + 1,
      projectId,
      checkedProducts,
      checkedStatus,
      searchField.searchParam,
      sortFilter
    );
  };

  onChangePageChange = e => {
    this.setState({ goToPage: e.target.value });
  };

  syncSelectedFilters = () => {
    const { selectedFilters } = this.props;
    const productFilters = selectedFilters.filter(
      filter => filter.filterType === "product"
    );
    const statusFilters = selectedFilters.filter(
      filter => filter.filterType === "status"
    );
    this.setState({
      checkedProducts: productFilters,
      checkedStatus: statusFilters
    });
  };

  onButtonClick = () => {
    const { fetchOfferList, projectId, searchField, sortFilter } = this.props;
    const { checkedStatus, checkedProducts } = this.state;

    const { goToPage } = this.state;
    const page = parseInt(goToPage, number);

    fetchOfferList(
      page,
      projectId,
      checkedProducts,
      checkedStatus,
      searchField.searchParam,
      sortFilter
    );
  };

  onClickSortColumn = field => {
    const {
      fetchOfferList,
      projectId,
      page,
      searchField,
      sortFilter
    } = this.props;
    const { checkedStatus, checkedProducts } = this.state;

    if (sortFilter === "" || sortFilter[0] === "-") {
      fetchOfferList(
        page,
        projectId,
        checkedProducts,
        checkedStatus,
        searchField.searchParam,
        field
      );
    } else {
      fetchOfferList(
        page,
        projectId,
        checkedProducts,
        checkedStatus,
        searchField.searchParam,
        `-${field}`
      );
    }
  };

  onExportButtonClick = () => {
    const { projectId, queryFilter } = this.props;
    fetch(window.API_URL+`/api/export/?project=${projectId}&${queryFilter}`, {
      headers: { Authorization: axios.defaults.headers.common.Authorization }
    }).then(response => {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      
      today = mm + '/' + dd + '/' + yyyy;      
      const filename = "export_offer_list_"+today+".xlsx";
      // const filename = response.headers
      //   .get("Content-Disposition")
      // .split("filename=")[1];
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  onOfferModalButtonClick = () => {
    this.setState({
      isModalVisible: true
    })
    
    console.log(this.state.isModalVisible)
  };

  
  getHeaderClass = (sortFilter, header) => {
    if (sortFilter === header) {
      return "btn-sort-arrow-up";
    }
    if (sortFilter === `-${header}`) {
      return "btn-sort-arrow-down";
    }

    return "btn-sort-arrow-default";
  };

  render() {
    const {
      offerList,
      offerIsLoading,
      count,
      totalPages,
      projectId,
      page,
      sortFilter,
      user
    } = this.props;
    const { goToPage } = this.state;
    return (
      <div className="offers-list">
        <h3 className="offers-list__title">Offers List</h3>
        <div className="row offers-list-utilities">
          <div className="col-8">
            <div className="offers-list-utilities__filters">
              <OfferSearch />
              <OfferFilters />
              <SelectedFilters />
            </div>
          </div>
          <div className="col-4">
            <div className="offers-list-utilities__add">
              <button
                type="button"
                className="btn btn-tp-default btn-export"
                onClick={this.onExportButtonClick}
              >
                EXPORT XLS
              </button>
              <button
                type="button"
                className="btn btn-tp-default"
                data-toggle="modal"
                data-target="#createOffer"
                onClick={this.onOfferModalButtonClick}
              >
                + ADD OFFER
              </button>
              <CreateOfferModal
                handleOfferCreation={this.refetchInitialOfferList}
                projectId={projectId}
                isvisible={this.state.isModalVisible}
                onmodalclose={
                  ()=>{
                    this.setState({
                      isModalVisible: false
                    })
                  }
                }
              />
            </div>
          </div>
        </div>
        {offerIsLoading ? (
          <div className="loading">
            <p>loading</p>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        ) : (
          <React.Fragment>
            <div className="table-responsive-sm">
              <table className="table table-tp">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="header-sort-list"
                      onClick={() => this.onClickSortColumn("product_code")}
                    >
                      product id
                      <span
                        className={`sticky-table btn-sort-column ${this.getHeaderClass(
                          sortFilter,
                          "product_code"
                        )}`}
                      >
                        &nbsp;
                      </span>
                    </th>

                    <th
                      scope="col"
                      className="header-sort-list"
                      onClick={() => this.onClickSortColumn("url")}
                    >
                      url
                      <span
                        className={`btn-sort-column ${this.getHeaderClass(
                          sortFilter,
                          "url"
                        )}`}
                      >
                        &nbsp;
                      </span>
                    </th>

                    <th scope="col">marketplace</th>
                    <th
                      scope="col"
                      className="header-sort-list"
                      onClick={() => this.onClickSortColumn("price")}
                    >
                      price
                      <span
                        className={`btn-sort-column ${this.getHeaderClass(
                          sortFilter,
                          "price"
                        )}`}
                      >
                        &nbsp;
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="header-sort-list"
                      onClick={() => this.onClickSortColumn("seller")}
                    >
                      seller
                      <span
                        className={`btn-sort-column ${this.getHeaderClass(
                          sortFilter,
                          "seller"
                        )}`}
                      >
                        &nbsp;
                      </span>
                    </th>
                    <th scope="col">active</th>
                    <th
                      scope="col"
                      className="header-sort-list"
                      onClick={() => this.onClickSortColumn("last_updated")}
                    >
                      updated
                      <span
                        className={`btn-sort-column ${this.getHeaderClass(
                          sortFilter,
                          "last_updated"
                        )}`}
                      >
                        &nbsp;
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="header-sort-list"
                      onClick={() => this.onClickSortColumn("created")}
                    >
                      created
                      <span
                        className={`btn-sort-column ${this.getHeaderClass(
                          sortFilter,
                          "created"
                        )}`}
                      >
                        &nbsp;
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="header-sort-list"
                      onClick={() => this.onClickSortColumn("status")}
                    >
                      status
                      <span
                        className={`btn-sort-column ${this.getHeaderClass(
                          sortFilter,
                          "status"
                        )}`}
                      >
                        &nbsp;
                      </span>
                    </th>
                   {user?.permissions.includes('core.allow_offer_approval') &&   
                    <th scope="col">approval</th>}
		   {/* <th scope="col">approval</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {offerList &&
                    offerList.map(offer => (
                      <tr
                        key={offer.id}
                        className={` ${
                          offer.status === "RE" ? "offer-is-rejected" : ""
                        }`}
                      >
                        <td
                          className={`product ${
                            offer.status === "AP" ||
                            offer.status === "FA" ||
                            offer.status === "PU" ||
                            offer.status === "BO" ||
                            offer.status === "PC" ||
                            offer.status === "NO" ||
                            offer.status === "LA"
                              ? "approved-flag"
                              : ""
                          }`}
                        >
                          <Link
                            className="product__link"
                            to={`/project-detail/offers/${offer.id}`}
                          >
                            <div className="product__details">
                              <span className="product__id limit-characters-144">
                                {offer.product_code}
                              </span>
                              <span className="product__name limit-characters-144">
                                {offer.product_name}
                              </span>
                            </div>
                          </Link>
                        </td>
                        <td>
                          <a
                            className="product__url"
                            title={offer.url}
                            href={offer.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {offer.url}
                          </a>
                        </td>
                        <td>
                          <span className="limit-characters-100">
                            {offer.marketplace}
                          </span>
                        </td>
                        <td>
                          <span className="limit-characters-100">
                            R${offer.price}
                          </span>
                        </td>
                        <td>
                          <span className="break-2-line">{offer.seller}</span>
                        </td>
                        <td>
                          <span
                            className={`badge tp-badge-onoff-active ${
                              offer.onoff_is_active
                                ? "tp-badge-onoff-active--yes"
                                : "tp-badge-onoff-active--no"
                            }`}
                          >
                            {offer.onoff_is_active ? "yes" : "no"}
                          </span>
                        </td>
                        <td>{formatDate(offer.last_updated)}</td>
                        <td>{formatDate(offer.created)}</td>
                        <td>
                          <span className="product__status">
                            {renameStatusOffer(offer.status)}
                          </span>
                        </td>
                        {offer.status === "PE" ? (
                          <td>
                           {user?.permissions.includes('core.allow_offer_approval') &&
                            <div className="product__group-actions">
                              <button
                                type="button"
                                className="btn product__btn-icon"
                                tabIndex="0"
                                data-toggle="modal"
                                data-target="#approveOfferModal"
                              >
                                <span className="icon-default icon-approve">
                                  &nbsp;
                                </span>
                              </button>
                              <OfferModal
                                eventName="approve"
                                offerId={offer.id}
                                productName={offer.product_name}
                                offerStatus="AP"
                              />
                              <button
                                type="button"
                                className="btn product__btn-icon"
                                tabIndex="0"
                                data-toggle="modal"
                                data-target="#rejectOfferModal"
                              >
                                <span className="icon-default icon-recuse">
                                  &nbsp;
                                </span>
                              </button>
                              <OfferModal
                                eventName="reject"
                                offerId={offer.id}
                                productName={offer.product_name}
                                offerStatus="RE"
                              />
                            </div>}
                          </td>
                        ) : (
                          <td>
                            {offer.status === "AP" ||
                            offer.status === "FA" ||
                            offer.status === "PU" ||
                            offer.status === "BO" ||
                            offer.status === "PC" ||
                            offer.status === "NO" ||
                            offer.status === "LA" ? (
                              <span className="badge tp-badge-approved">
                                Approved
                              </span>
                            ) : (
                              ""
                            )}
                            {offer.status === "RE" ? (
                              <span className="badge tp-badge-rejected">
                                Rejected
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {count >= 1 ? (
              <div className="pagination-row">
                <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={totalPages}
                  marginPagesDisplayed={0}
                  forcePage={page - 1}
                  pageRangeDisplayed={4}
                  onPageChange={this.handlePageChange}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  // This prop was used for disable onPageChange callback with initial page
                  disableInitialCallback
                />

                <span className="pagination-row__text">
                  Showing {offerList.length} of {count} results
                </span>

                <span className="pagination-row__text">Go to page </span>
                <div className="form-number-page">
                  <input
                    type="number"
                    className="pagination-row__input no-spin"
                    value={goToPage}
                    onChange={this.onChangePageChange}
                  />
                  <button
                    type="button"
                    className="btn pagination-row__button icon-default"
                    onClick={this.onButtonClick}
                  >
                    &nbsp;
                  </button>
                </div>
              </div>
            ) : (
              <div className="no-offer">
                <span>
                  There is still no offer to display. You can add new offers by
                  clicking on the <b>Add Offer</b> button.
                </span>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

OfferList.propTypes = {
  offerList: PropTypes.array,
  offerIsLoading: PropTypes.bool,
  fetchOfferList: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  count: PropTypes.number,
  projectId: PropTypes.number.isRequired,
  queryFilter: PropTypes.string,
  selectedFilters: PropTypes.array,
  searchField: PropTypes.object,
  page: PropTypes.number,
  sortFilter: PropTypes.string
};

OfferList.defaultProps = {
  offerIsLoading: false,
  offerList: undefined,
  totalPages: undefined,
  count: undefined,
  queryFilter: "",
  searchField: {},
  selectedFilters: [],
  page: 1,
  sortFilter: "-last_updated"
};

const mapStateToProps = state => ({
  offerList: state.projectDetail.offerList,
  offerIsLoading: state.projectDetail.offerIsLoading,
  totalPages: state.projectDetail.totalPages,
  count: state.projectDetail.count,
  previous: state.projectDetail.previous,
  next: state.projectDetail.next,
  queryFilter: state.projectDetail.queryFilter,
  selectedFilters: state.projectDetail.selectedFilters,
  searchField: state.projectDetail.searchField,
  sortFilter: state.projectDetail.sortFilter,
  page: state.projectDetail.page,
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  fetchOfferList: (page, projectId, productName, status, search, sortFilter) =>
    dispatch(
      fetchOfferList(page, projectId, productName, status, search, sortFilter)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferList));
