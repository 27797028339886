import axios from "axios";

import {
  FETCH_OFFER_LIST_REQUEST,
  FETCH_OFFER_LIST_SUCCESS,
  FETCH_OFFER_LIST_FAILURE,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  UPDATE_OFFER_REQUEST,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILURE,
  CLEAR_CREATE_OFFER_IS_SUCCESSFUL,
  FETCH_OFFER_DETAIL_REQUEST,
  FETCH_OFFER_DETAIL_SUCCESS,
  FETCH_OFFER_DETAIL_FAILURE,
  FETCH_NOTES_LIST_SUCCESS,
  FETCH_NOTES_LIST_REQUEST,
  FETCH_NOTES_LIST_FAILURE,
  FETCH_ASSETS_LIST_SUCCESS,
  FETCH_ASSETS_LIST_REQUEST,
  FETCH_ASSETS_LIST_FAILURE,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_REQUEST,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_PRODUCT_LIST_FOR_AUTOFILL_SUCCESS,
  FETCH_PRODUCT_LIST_FOR_AUTOFILL_REQUEST,
  FETCH_PRODUCT_LIST_FOR_AUTOFILL_FAILURE,
  FETCH_PROJECT_DETAIL_REQUEST,
  FETCH_PROJECT_DETAIL_SUCCESS,
  FETCH_PROJECT_DETAIL_FAILURE,
  CHANGE_LEGALITY_ANALYSIS_REQUEST,
  CHANGE_LEGALITY_ANALYSIS_SUCCESS,
  CHANGE_LEGALITY_ANALYSIS_FAILURE,
  CLEAR_CHANGE_LEGALITY_ANALYSIS_IS_SUCCESSFUL,
  CLEAR_AUTOFILL_PRODUCT
} from "./types";

import { OFFER_STATUS } from "../constants";

export const createOffer = (
  url,
  marketplace,
  productCode,
  productName,
  seller,
  price,
  location,
  project
) => {
  const requestData = {
    url,
    marketplace,
    product_code: productCode,
    product_name: productName,
    seller,
    price,
    location,
    project
  };

  return {
    types: {
      request: CREATE_OFFER_REQUEST,
      success: CREATE_OFFER_SUCCESS,
      failure: CREATE_OFFER_FAILURE
    },
    apiCallFunction: () => axios.post(window.API_URL + `/api/offers/`, requestData)
  };
};

export const updateOffer = (id, fields) => {
  const requestData = fields;

  return {
    types: {
      request: UPDATE_OFFER_REQUEST,
      success: UPDATE_OFFER_SUCCESS,
      failure: UPDATE_OFFER_FAILURE
    },
    apiCallFunction: () => axios.patch(window.API_URL + `/api/offers/${id}/`, requestData)
  };
};

export const clearCreateOfferIsSuccessful = () => ({
  type: CLEAR_CREATE_OFFER_IS_SUCCESSFUL
});

export const clearAutofillProduct = () => ({
  type: CLEAR_AUTOFILL_PRODUCT
});

export const fetchOfferDetail = id => ({
  types: {
    request: FETCH_OFFER_DETAIL_REQUEST,
    success: FETCH_OFFER_DETAIL_SUCCESS,
    failure: FETCH_OFFER_DETAIL_FAILURE
  },
  apiCallFunction: () => axios.get(window.API_URL + `/api/offers/${id}/`)
});

export const fetchNotesList = id => ({
  types: {
    request: FETCH_NOTES_LIST_REQUEST,
    success: FETCH_NOTES_LIST_SUCCESS,
    failure: FETCH_NOTES_LIST_FAILURE
  },
  apiCallFunction: () => axios.get(window.API_URL + `/api/offers/${id}/notes/`)
});

export const fetchProductListByName = (name = "", projectId) => ({
  types: {
    request: FETCH_PRODUCT_LIST_REQUEST,
    success: FETCH_PRODUCT_LIST_SUCCESS,
    failure: FETCH_PRODUCT_LIST_FAILURE
  },
  apiCallFunction: () =>
    axios.get(window.API_URL + `/api/products/?name=${name}&project=${projectId}`)
});

export const fetchProductListByProductCode = (productCode = "", projectId) => ({
  types: {
    request: FETCH_PRODUCT_LIST_FOR_AUTOFILL_REQUEST,
    success: FETCH_PRODUCT_LIST_FOR_AUTOFILL_SUCCESS,
    failure: FETCH_PRODUCT_LIST_FOR_AUTOFILL_FAILURE
  },
  apiCallFunction: () =>
    axios.get(window.API_URL + `/api/products/?product_code=${productCode}&project=${projectId}`)
});

export const fetchProjectDetail = id => ({
  types: {
    request: FETCH_PROJECT_DETAIL_REQUEST,
    success: FETCH_PROJECT_DETAIL_SUCCESS,
    failure: FETCH_PROJECT_DETAIL_FAILURE
  },
  apiCallFunction: () => axios.get(window.API_URL + `/api/projects/${id}/`)
});

export const changeLegalityAnalysis = (id, legalityAnalysis, noteText) => {
  const requestData = {
    legality_analysis: legalityAnalysis,
    note_text: noteText
  };

  return {
    types: {
      request: CHANGE_LEGALITY_ANALYSIS_REQUEST,
      success: CHANGE_LEGALITY_ANALYSIS_SUCCESS,
      failure: CHANGE_LEGALITY_ANALYSIS_FAILURE
    },
    apiCallFunction: () => axios.patch(window.API_URL + `/api/offers/${id}/`, requestData)
  };
};

export const clearChangeLegalityAnalysisIsSuccessful = () => ({
  type: CLEAR_CHANGE_LEGALITY_ANALYSIS_IS_SUCCESSFUL
});

export const fetchAssetsList = id => ({
  types: {
    request: FETCH_ASSETS_LIST_REQUEST,
    success: FETCH_ASSETS_LIST_SUCCESS,
    failure: FETCH_ASSETS_LIST_FAILURE
  },
  apiCallFunction: () => axios.get(window.API_URL + `/api/offers/${id}/assets/`)
});

export const fetchOfferList = (
  pageNumber,
  projectId,
  selectedProductFilters,
  selectedStatusFilters,
  searchParam = "",
  orderColumn
) => {
  const matchingStatusList = OFFER_STATUS.filter(
    item => selectedStatusFilters.filter(status => status.id === item.id).length
  );
  const statusFilters = matchingStatusList.map(status => ({
    ...status,
    filterType: "status"
  }));
  const statusIds = matchingStatusList.map(item => item.id);

  const productNames = selectedProductFilters.map(item => item.name);
  const productFilters = selectedProductFilters.map(product => ({
    ...product,
    filterType: "product"
  }));
  const searchFilter = { searchParam, filterType: "search" };
  const paramRequest = `product_name=${productNames}&status=${statusIds}&q=${searchParam}`;

  return {
    types: {
      request: FETCH_OFFER_LIST_REQUEST,
      success: FETCH_OFFER_LIST_SUCCESS,
      failure: FETCH_OFFER_LIST_FAILURE
    },
    apiCallFunction: () =>
      axios.get(
        window.API_URL + `/api/offers/?page=${pageNumber}&project=${projectId}&${paramRequest}&ordering=${orderColumn}`
      ),

    extraData: {
      selectedFilters: [...productFilters, ...statusFilters],
      searchField: searchFilter,
      queryFilter: paramRequest,
      page: pageNumber,
      sortFilter: orderColumn
    }
  };
};

export const removeFilter = (
  pageNumber,
  projectId,
  selectedFilters,
  filter,
  searchParam = ""
) => {
  const newSelectedFilters = selectedFilters.filter(
    selectedFilter => selectedFilter !== filter
  );
  const productFilters = newSelectedFilters.filter(
    filter => filter.filterType === "product"
  );
  const statusFilters = newSelectedFilters.filter(
    filter => filter.filterType === "status"
  );
  return fetchOfferList(
    pageNumber,
    projectId,
    productFilters,
    statusFilters,
    searchParam
  );
};
