import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { applyMiddleware, createStore, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { apiMiddleware } from "react-redux-api-tools";
import rootReducer from "./rootReducer";
import { isEmpty } from "./Utils";
import { setToken, setCurrentUser } from "./login/actions";

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore(preloadedState) {
  const middleware = [thunk, routerMiddleware(history), apiMiddleware];
  const store = createStore(
    rootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  // check localStorage
  if (!isEmpty(localStorage.getItem("token"))) {
    store.dispatch(setToken(localStorage.getItem("token")));
  }
  if (!isEmpty(localStorage.getItem("user"))) {
    const user = JSON.parse(localStorage.getItem("user"));
    store.dispatch(setCurrentUser(user, ""));
  }

  return store;
}
