import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";

export default function requireAuth(Component) {
  class AuthenticatedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.checkAuth();
    }

    componentDidUpdate() {
      this.checkAuth();
    }

    checkAuth() {
      const { isAuthenticated, location: pathname, dispatch } = this.props;

      if (!isAuthenticated) {
        const redirectAfterLogin = pathname;
        dispatch(push(`/login?next=${redirectAfterLogin}`));
      }
    }

    render() {
      const { isAuthenticated } = this.props;

      return (
        <div>
          {isAuthenticated === true ? (
            // Since this is a component that renders a component, we explicitly need to repass the props
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Component {...this.props} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )}
        </div>
      );
    }
  }
  AuthenticatedComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    dispatch: PropTypes.func.isRequired
  };

  const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
