import axios from "axios";
import { toast } from "react-toastify";
import enGB from "date-fns/locale/en-GB";
import formatRelative from "date-fns/formatRelative";
import $ from "jquery";
import "bootstrap";

export const setAxiosAuthToken = token => {
  if (typeof token !== "undefined" && token) {
    // Apply for every request
    axios.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common.Authorization;
  }
};

export const toastOnError = error => {
  if (error.response) {
    toast.error(JSON.stringify(error.response.data));
  } else if (error.message) {
    toast.error(JSON.stringify(error.message));
  } else {
    toast.error(JSON.stringify(error));
  }
};

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const renameStatusOffer = status => {
  if (status === "PE") {
    status = "Pending Approval";
  } else if (status === "AP") {
    status = "Purchase Approved";
  } else if (status === "RE") {
    status = "Purchase Rejected";
  } else if (status === "PC") {
    status = "Purchase Cancelled";
  } else if (status === "PU") {
    status = "Purchased";
  } else if (status === "BO") {
    status = "Bought";
  } else if (status === "FA") {
    status = "Forensis Analysis";
  } else if (status === "LA") {
    status = "Legality Analysis";
  } else if (status === "NO") {
    status = "Notified";
  }
  return status;
};

export const renameTagNote = status => {
  if (status === "LA") {
    status = "legality analysis";
  } else if (status === "FA") {
    status = "forensic analysis";
  } else if (status === "PU") {
    status = "purchase";
  } else if (status === "PA") {
    status = "payment";
  } else if (status === "NE") {
    status = "negotiation";
  } else if (status === "DE") {
    status = "delivery";
  } else if (status === "SE") {
    status = "serial";
  } else if (status === "NO") {
    status = "notice";
  }
  return status;
};

export const formatDate = date => {
  const formatRelativeLocale = {
    lastWeek: "'Last' eeee",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "'Next' eeee",
    other: "dd.MM.yyyy"
  };

  const locale = {
    ...enGB,
    formatRelative: token => formatRelativeLocale[token]
  };

  const teste = new Date(date);
  const formatedDate = formatRelative(teste, new Date(), { locale });
  return formatedDate;
};

export const showModalLegalityAnalysis = () => {
  const title = $(this).val();
  $(".modal-title").html(title);
  $("#textNoteModal").modal("show");
};
