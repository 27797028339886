export const OFFER_STATUS = [
  { id: "PE", name: "Pending" },
  { id: "AP", name: "Approved" },
  { id: "RE", name: "Rejected" },
  { id: "PU", name: "Purchased" },
  { id: "BO", name: "Bought" },
  { id: "PC", name: "Purchase Canceled" },
  { id: "FA", name: "In Forensis Analysis" },
  { id: "LA", name: "In Legality Analysis" },
  { id: "NO", name: "Notified" }
];

