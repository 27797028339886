/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

class PreviewAssetModal extends Component {
  parseFileType = file =>
    file
      .split("?")[0]
      .split(".")
      .pop();

  isImageFile = file => ["png", "jpeg"].includes(this.parseFileType(file));

  isVideoFile = file => ["mp4", "ogg"].includes(this.parseFileType(file));

  // isPdfFile = file => {};

  render() {
    const { asset, handleClose, show } = this.props;
    return (
      <Modal show={show} onHide={handleClose} dialogClassName="preview-modal">
        <Modal.Header closeButton>
          <Modal.Title>{asset.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {asset.file && this.isImageFile(asset.file) ? (
            <img alt="asset img" src={asset.file} />
          ) : asset.file && this.isVideoFile(asset.file) ? (
            <video width="100%" controls>
              <source src={asset.file} type="video/mp4" />
              <source src={asset.file} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          ) : asset.file ? (
            <p className="no-preview-available">
              Preview not available for this file format.
            </p>
          ) : (
            <></>
          )}
          <p>{asset.text}</p>
        </Modal.Body>
      </Modal>
    );
  }
}

PreviewAssetModal.propTypes = {
  asset: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PreviewAssetModal;
