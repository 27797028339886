import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { loginReducer } from "./login/reducers";
import { projectDetailReducer } from "./ProjectDetail/reducers";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth: loginReducer,
    projectDetail: projectDetailReducer
  });

export default createRootReducer;
