import React from "react";
import PropTypes from "prop-types";

import Header from "./Header";

class BaseTemplate extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: undefined
  };

  render() {
    const { children } = this.props;
    return (
      <div className="base-template">
        <Header />
        {React.cloneElement(children, this.props)}
      </div>
    );
  }
}

export default BaseTemplate;
